import React, { useState, useRef, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Box, Drawer, List, Typography, Divider, IconButton, Grid,  Button, Container }from '@mui/material';
import { Link } from "react-router-dom";

import TuneIcon from '@mui/icons-material/Tune';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import productTitleBg from './../../assets/productTitleBg.png';
import { ProductItems } from './ProductItems';
import Footer from '../../components/footer/Footer';
import loadmore from './../../assets/loadmore.png';

import Collapse from '@mui/material/Collapse';
import { ProductCategory } from './ProductCategory';

import { useParams } from 'react-router-dom';


const InnerPageTitle = [
    {
      Title: "Birthday Cakes",
    },
    {
      Title: "Wedding Cakes",
    },
    {
      Title: "Anniversary Cakes",
    },
    {
      Title: "Super Hero Cakes",
    },
    {
      Title: "Photo Cakes",
    },
    {
      Title: "Dream Shape Cakes",
    },
  ];
  
  const InnerpageTitleSec = styled(Box)({
    backgroundImage: `url(${productTitleBg})`,
    minHeight: "440px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width:600px)": {
      Height: "100%",
  }
  });
  
  const CustomContainer = styled(Box)({
    maxWidth: '1050px',
    margin: '0 auto',
    "@media (max-width:600px)": {
       padding: '20px'
  }
  });
  
  const ProductItem = styled(Grid)(({ theme }) => ({
    maxWidth: '484px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }));


const drawerWidth = 280;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));



export default function Product() {

  const { param } = useParams();
  const [product, setProduct] = useState([]);

  const getProduct=async(id)=>{
    const response = await fetch("https://specialshapecatalogue.mioamoreshop.com/mio-amore/public/get_product_by_cat_id.php?cat_id=" + encodeURIComponent(id));
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    
    return data;
  }

    const [listOpen, setListOpen] = useState({});

    const handleCategoryToggle = (category) => {
        setListOpen((prevState) => ({
          ...prevState,
          [category]: !prevState[category],
        }));
      };
    
const [displayCount, setDisplayCount] = useState(4);
  const lastProductRef = useRef(null);
  const containerRef = useRef(null);

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 4);
  };

  useEffect(() => { console.log("firing useeffect");
    // Scroll to the last added item if it's not visible
    if (lastProductRef.current && containerRef.current) {
      const lastItemBottom = lastProductRef.current.offsetTop + lastProductRef.current.clientHeight;
      const containerBottom = containerRef.current.clientHeight + containerRef.current.scrollTop;
      if (lastItemBottom > containerBottom) {
        lastProductRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }

    //call get product

    getProduct(param)
      .then((result) => {
        // Update the state with the fetched data
        console.log("setting_data",result)
        setProduct(result);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [displayCount,param]);


  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
    
  return (
    <Box sx={{ display: 'flex' }}>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            Cake Categories
          </Typography>
        </DrawerHeader>
        <Divider />
        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
         {ProductCategory().map((categoryItem, index) => (
            <div key={index}>
              <ListItemButton
                onClick={() => handleCategoryToggle(categoryItem.category)}
              >
                <ListItemIcon sx={{minWidth: 40}}>
                  <span style={{ display: 'block', height: 30, width: 30, }}>
                  {categoryItem.icon}
                  </span>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: '20px', fontWeight: 600, color: '#4d4d4f'}} primary={categoryItem.category} />
                {listOpen[categoryItem.category] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              <Collapse
                in={listOpen[categoryItem.category]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                {categoryItem.subCategory.map((subCategoryItem, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <ListItemButton sx={{ pl: 4 }}>
                    <Link className='subItem' to={`/products/${subCategoryItem.id}`}>
                      <ListItemText primaryTypographyProps={{fontSize: 18,}} primary={subCategoryItem.name} />
                      </Link>
                    </ListItemButton>
                    {subIndex < categoryItem.subCategory.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
                
              </Collapse>
            </div>
          ))}
    </List>
      </Drawer>



      
      <Main open={open}>
      <InnerpageTitleSec className='inner-banner' >
        <Typography variant='h3' fontSize={95} fontWeight={600} color={"white"} sx={{
            textAlign: 'center',
            "@media (max-width:600px)": {
                fontSize: '65px'
           }
        }}>
          {InnerPageTitle[0].Title}
        </Typography>
      </InnerpageTitleSec>
        <DrawerHeader />
        
      <Container>
        <Box mt={2}>
            <Button variant='text' size='large' startIcon={<TuneIcon style={{fontSize: 35,}}/>} 
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, fontSize:35, ...(open && { display: 'none' }) }}
            >Filter</Button>
            
        </Box>
      </Container>
      <CustomContainer ref={containerRef}>
        <Grid container spacing={8}>
          {product.slice(0, displayCount).map((item, index) => (
            <ProductItem
              item
              md={6}
              my={3}
              key={index}
              ref={index === displayCount - 1 ? lastProductRef : null}
            >
             <img src={item.ProductImg} alt="" />

              <Typography variant='body1' mt={3}>
                <span style={{ fontSize: 20, fontWeight: 500, color: '#95177b' }}>Name : </span>
                <span style={{ fontSize: 20, fontWeight: 600, color: '#4d4d4f' }}>{item.ProductTitle}</span>
              </Typography>

              {item.flavour && (
              <Typography variant='body1' mt={1}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Flavour : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.flavour}</span>
              </Typography>
              )}

              {item.ProductFlavour && (
              <Typography variant='body1' mt={1}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Flavour With Butter Cream : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.ProductFlavour}</span>
              </Typography>
              )}

              {item.butter_cream_price && (
              <Typography variant='body1' mt={0}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Butter Cream Price : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.butter_cream_price}/1kg</span>
              </Typography>
              )}

              {item.flavour_fresh && (
              <Typography variant='body1' mt={1}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Flavour With Fresh Cream  : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.flavour_fresh}</span>
              </Typography>
              )}

              {item.fresh_cream_price && (
              <Typography variant='body1' mt={0}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Fresh Cream Price : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.fresh_cream_price}/1kg</span>
              </Typography>
              )}

              <Typography variant='body1' mt={1}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Code Number : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f', marginRight: 10 }}>{item.CodeNumber},</span>
              
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Minimum Weight : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f', marginRight: 10 }}>{item.MinimumWeight},</span>
              </Typography>
              
              <Typography variant='body1' mt={0}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Minimum Delivery Time : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.DeliveryTime} Hrs</span>
              </Typography>

              {item.mrp && (
              <Typography variant='body1' mt={0}>
                <span style={{ fontSize: 16, fontWeight: 500, color: '#95177b' }}>Price : </span>
                <span style={{ fontSize: 16, fontWeight: 400, color: '#4d4d4f' }}>{item.mrp}</span>
              </Typography>
              )}

              
             
                         
            </ProductItem>
          ))}
        </Grid>
        {displayCount < product.length && (
          <Box mt={20} display="flex" justifyContent="center">
            <IconButton onClick={handleLoadMore}>
            <img src={loadmore} alt="" />
            </IconButton>
          </Box>
        )}
      </CustomContainer>
      <Footer/>
      </Main>
    </Box>
  );
}